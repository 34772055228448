import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import config from '../../config'
import Helmet from 'react-helmet'

import { Article, OrangeLayout } from '../components'

const IndexPage = ({
  data: {
    allMdx: { nodes: posts },
  },
}) => (
  <OrangeLayout title="An unorganised nest of shiny things">
    <Helmet title={config.siteTitle}></Helmet>
    <p>I'm a random black and white corvid who <Link to="/projects">codes stuff</Link>, <a href="https://lightningphoenix.deviantart.com/gallery">makes art</a>,
    and sometimes rambles about stuff (as a glance slightly downwards will show you).</p>
    <p style={{fontSize: "0.75em"}}>If you want to talk to me, the localpart of my email, Matrix, and XMPP addresses is <code>kythyria</code>. The domain, of course, is this one.</p>
    <hr/>
    <h1>Recent Posts</h1>
    {posts.map(post => (
          <Article
            title={post.frontmatter.title}
            date={post.fields.date}
            excerpt={post.excerpt}
            timeToRead={post.timeToRead}
            slug={post.fields.slug}
            categories={post.frontmatter.categories}
            key={post.fields.slug}
          />
        ))}
  </OrangeLayout>
)

export default IndexPage

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
  }).isRequired,
}

export const IndexQuery = graphql`
  query IndexQuery {
    allMdx(sort: { fields: [fields___date], order: DESC }) {
      nodes {
        fields {
          slug
          date(formatString: "MM/DD/YYYY")
        }
        frontmatter {
          title
          categories
        }
        excerpt(pruneLength: 200)
        timeToRead
      }
    }
  }
`
